//
// Multi Select
//

.form-multi-select {
  position: relative;
  display: block;
  width: 100%;
  padding: $form-multi-select-padding-y ($form-multi-select-padding-x + $form-multi-select-indicator-padding) $form-multi-select-padding-y $form-multi-select-padding-x;
  font-family: $form-multi-select-font-family;
  @include font-size($form-multi-select-font-size);
  font-weight: $form-multi-select-font-weight;
  line-height: $form-multi-select-line-height;
  color: var(--#{$variable-prefix}form-multi-select-color, $form-multi-select-color);
  background-color: var(--#{$variable-prefix}form-multi-select-bg, $form-multi-select-bg);
  background-image: escape-svg($form-multi-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-multi-select-bg-position;
  background-size: $form-multi-select-bg-size;
  border: $form-multi-select-border-width solid var(--#{$variable-prefix}form-multi-select-border-color, $form-multi-select-border-color);
  @include border-radius($form-multi-select-border-radius, 0);
  appearance: none;

  &:disabled,
  &.disabled {
    color: var(--#{$variable-prefix}form-multi-select-disabled-color, $form-multi-select-disabled-color);
    background-color: var(--#{$variable-prefix}form-multi-select-disabled-bg, $form-multi-select-disabled-bg);
  }

  &.form-multi-select-with-cleaner {
    padding: $form-multi-select-padding-y ($form-multi-select-padding-x + $form-multi-select-indicator-padding + $form-multi-select-cleaner-width + 2 * $form-multi-select-cleaner-padding-x) $form-multi-select-padding-y $form-multi-select-padding-x;
  }
}

.form-multi-select-selection-tags {
  min-height: calc(var(--form-multi-select-padding-y) * 2 + var(--form-multi-select-font-size) * var(--form-multi-select-line-height) + var(--form-multi-select-border-width) * 2);
  padding: $form-multi-select-selection-tags-padding-y ($form-multi-select-padding-x + $form-multi-select-indicator-padding) $form-multi-select-selection-tags-padding-y $form-multi-select-selection-tags-padding-x;

  &.form-multi-select-with-cleaner {
    padding: $form-multi-select-selection-tags-padding-y ($form-multi-select-padding-x + $form-multi-select-indicator-padding + $form-multi-select-cleaner-width + 2 * $form-multi-select-cleaner-padding-x) $form-multi-select-selection-tags-padding-y $form-multi-select-selection-tags-padding-x;
  }

  .form-multi-select-search {
    height: ($form-multi-select-padding-y - $form-multi-select-selection-tags-padding-y) * 2 + ($form-multi-select-font-size * $form-multi-select-line-height);
    margin-left: $form-multi-select-padding-x;
  }
}

.form-multi-select-selection {
  display: inline;
  padding: 0;
}

.form-multi-select-selection-cleaner {
  position: absolute;
  top: 50%;
  right: 2rem;
  box-sizing: content-box;
  width: $form-multi-select-cleaner-width;
  height: $form-multi-select-cleaner-height;
  padding: $form-multi-select-cleaner-padding-y $form-multi-select-cleaner-padding-x;
  background: transparent var(--#{$variable-prefix}form-multi-select-cleaner-bg, escape-svg($form-multi-select-cleaner-bg)) center / $form-multi-select-cleaner-width auto no-repeat;
  border: 0; // for button elements
  border-right: 1px solid var(--#{$variable-prefix}form-multi-select-cleaner-border-color, $form-multi-select-cleaner-border-color);
  transform: translateY(-50%);

  &:hover {
    background: transparent var(--#{$variable-prefix}form-multi-select-cleaner-hover-bg, escape-svg($form-multi-select-cleaner-hover-bg)) center / $form-multi-select-cleaner-width auto no-repeat;
  }

  &:focus {
    background: transparent var(--#{$variable-prefix}form-multi-select-cleaner-focus-bg, escape-svg($form-multi-select-cleaner-focus-bg)) center / $form-multi-select-cleaner-width auto no-repeat;
    outline: 0;
  }
}

.form-multi-select-search {
  max-width: 100%;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: var(--#{$variable-prefix}form-multi-select-search-color, $form-multi-select-search-color);
  background-color: var(--#{$variable-prefix}form-multi-select-search-bg, $form-multi-select-search-bg);
  border: 0;
  appearance: none;
  @include border-radius($form-multi-select-search-border-radius, 0);

  &:focus {
    outline: 0;
  }

  &[size] {
    display: none;
    margin-left: $form-multi-select-padding-x;
  }

  &:placeholder-shown {
    width: 100%;
  }
}

.form-multi-select-all {
  display: block;
  width: 100%;
  padding: $form-multi-select-select-all-padding-y $form-multi-select-select-all-padding-x;
  color: var(--#{$variable-prefix}form-multi-select-select-all-color, $form-multi-select-select-all-color);
  text-align: start;
  background-color: var(--#{$variable-prefix}form-multi-select-select-all-bg, $form-multi-select-select-all-bg);
  border: 0;
  border-bottom: 1px solid var(--#{$variable-prefix}form-multi-select-select-all-border-color, $form-multi-select-select-all-border-color);

  &:hover {
    color: var(--#{$variable-prefix}form-multi-select-select-all-hover-color, $form-multi-select-select-all-hover-color);
    background-color: var(--#{$variable-prefix}form-multi-select-select-all-hover-bg, $form-multi-select-select-all-hover-bg);
  }
}

.form-multi-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none; // none by default, but block on "open" of the menu
  width: 100%;
  margin-top: $form-multi-select-options-margin-top;
  background-color: var(--#{$variable-prefix}form-multi-select-options-bg, $form-multi-select-options-bg);
  background-clip: padding-box;
  border: $form-multi-select-options-border-width solid var(--#{$variable-prefix}form-multi-select-options-border-color, $form-multi-select-options-border-color);
  @include border-radius($form-multi-select-options-border-radius);
  @include elevation(4);
}

// Custom scroll bar for Safari and Chrome
.form-multi-select-options::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
}

.form-multi-select-options::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--cui-gray-500);
}

.form-multi-select-options::-webkit-scrollbar-track {
    border-radius: 50px;
}

.form-multi-select-options:hover::-webkit-scrollbar {
    display: block;
}

.form-multi-select-options::-webkit-scrollbar-thumb:hover {
  background: var(--cui-gray-700);
}

.form-multi-select-options { 
  padding: $form-multi-select-options-padding-y $form-multi-select-options-padding-x;
  font-size: $form-multi-select-options-font-size;
  color: var(--#{$variable-prefix}form-multi-select-options-color, $form-multi-select-options-color);
}

.form-multi-select-option {
  position: relative;
  padding: $form-multi-select-option-padding-y $form-multi-select-option-padding-x;
  margin-bottom: 2px;
  vertical-align: top;
  cursor: pointer;
  @include border-radius($form-multi-select-option-border-radius, 0);

  &:hover,
  &:focus {
    color: var(--#{$variable-prefix}form-multi-select-option-hover-color, $form-multi-select-option-hover-color);
    text-decoration: none;
    background-color: var(--#{$variable-prefix}form-multi-select-option-hover-bg, $form-multi-select-option-hover-bg);
  }

  &.disabled,
  &:disabled {
    color: var(--#{$variable-prefix}form-multi-select-option-disabled-color, $form-multi-select-option-disabled-color);
    pointer-events: none;
    background-color: transparent;
  }

  &.form-multi-select-option-with-checkbox {
    padding: $form-multi-select-option-padding-y $form-multi-select-option-padding-x $form-multi-select-option-padding-y calc(#{$form-multi-select-option-padding-x} + #{$form-multi-select-option-indicator-width}); // stylelint-disable-line function-disallowed-list

    &::before {
      position: absolute;
      top: .7rem;
      left: $form-multi-select-option-padding-x * .5;
      display: block;
      width: $form-multi-select-option-indicator-width;
      height: $form-multi-select-option-indicator-width;
      pointer-events: none;
      content: "";
      background-color: var(--#{$variable-prefix}form-multi-select-option-indicator-bg, $form-multi-select-option-indicator-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: var(--#{$variable-prefix}form-multi-select-option-indicator-border, $form-multi-select-option-indicator-border);
      @include border-radius($form-multi-select-option-indicator-border-radius);
    }
  }


  &.form-multi-selected {
    background-color: var(--#{$variable-prefix}form-multi-select-option-selected-bg, $form-multi-select-option-selected-bg);

    &::before {
      background-color: var(--#{$variable-prefix}form-multi-select-option-selected-indicator-bg, $form-multi-select-option-selected-indicator-bg);
      background-image: var(--#{$variable-prefix}form-multi-select-option-selected-indicator-bg-image, escape-svg($form-multi-select-option-selected-indicator-bg-image));
      border-color: var(--#{$variable-prefix}form-multi-select-option-selected-indicator-border-color, $form-multi-select-option-selected-indicator-border-color);
    }
  }
}

.form-multi-select-optgroup-label {
  padding: ($form-multi-select-option-padding-y * 1.5) ($form-multi-select-option-padding-x * .5) $form-multi-select-option-padding-y ($form-multi-select-option-padding-x * .5);
  font-size: 80%;
  font-weight: $form-multi-select-optgroup-label-font-weight;
  color: $form-multi-select-optgroup-label-color;
  text-transform: uppercase;
}

.form-multi-select-options-empty {
  padding: $form-multi-select-option-padding-y $form-multi-select-option-padding-x;
}


.form-multi-select-tag {
  display: inline-block;
  padding: $form-multi-select-tag-padding-y $form-multi-select-tag-padding-x;
  margin: $form-multi-select-tag-margin-y $form-multi-select-tag-margin-x;
  background-color: var(--#{$variable-prefix}form-multi-select-tag-bg, $form-multi-select-tag-bg);
  border: $form-multi-select-tag-border-width solid var(--#{$variable-prefix}form-multi-select-tag-border-color, $form-multi-select-tag-border-color);
  @include border-radius($form-multi-select-tag-border-radius);
}

.form-multi-select-tag-delete {
  padding: 0;
  margin-left: 6px;
  background-color: transparent;
  border: 0;
}

.form-multi-select.show {
  color: var(--#{$variable-prefix}form-multi-select-focus-color, $form-multi-select-focus-color);
  background-color: var(--#{$variable-prefix}form-multi-select-focus-bg, $form-multi-select-focus-bg);
  border-color: var(--#{$variable-prefix}form-multi-select-focus-border-color, $form-multi-select-focus-border-color);

  @if $enable-shadows {
    @include box-shadow($form-multi-select-box-shadow, $form-multi-select-focus-box-shadow);
  }

  @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $form-multi-select-focus-box-shadow;
  }

  .form-multi-select-search[size] {
    display: inline;
  }

  .form-multi-select-dropdown {
    z-index: $zindex-dropdown;
    display: initial;
  }
}

.form-multi-select-sm {
  padding-top: $form-multi-select-padding-y-sm;
  padding-bottom: $form-multi-select-padding-y-sm;
  padding-left: $form-multi-select-padding-x-sm;
  @include font-size($form-multi-select-font-size-sm);

  &.form-multi-select-selection-tags {
    min-height: calc((var(--form-multi-select-padding-y-sm) * 2) + (var(--form-multi-select-font-size-sm) * var(--form-multi-select-line-height)) + (var(--form-multi-select-border-width) * 2));
    padding: $form-multi-select-selection-tags-padding-y-sm ($form-multi-select-padding-x-sm + $form-multi-select-indicator-padding) $form-multi-select-selection-tags-padding-y-sm $form-multi-select-selection-tags-padding-x-sm;

    .form-multi-select-search {
      height: ($form-multi-select-padding-y-sm - $form-multi-select-selection-tags-padding-y-sm) * 2 + ($form-multi-select-font-size-sm * $form-multi-select-line-height);
    }
  }

  .form-multi-select-tag {
    padding: $form-multi-select-tag-padding-y-sm $form-multi-select-tag-padding-x-sm;
    margin: $form-multi-select-tag-margin-y-sm $form-multi-select-tag-margin-x-sm;
    @include border-radius($form-multi-select-tag-border-radius);
  }
}

.form-multi-select-lg {
  padding-top: $form-multi-select-padding-y-lg;
  padding-bottom: $form-multi-select-padding-y-lg;
  padding-left: $form-multi-select-padding-x-lg;
  @include font-size($form-multi-select-font-size-lg);

  &.form-multi-select-selection-tags {
    min-height: calc(var(--form-multi-select-padding-y) * 2 + var(--form-multi-select-font-size) * var(--form-multi-select-line-height) + var(--form-multi-select-border-width) * 2);
    padding: $form-multi-select-selection-tags-padding-y-lg ($form-multi-select-padding-x-lg + $form-multi-select-indicator-padding) $form-multi-select-selection-tags-padding-y-lg $form-multi-select-selection-tags-padding-x-lg;
  }

  .form-multi-select-tag {
    padding: $form-multi-select-tag-padding-y-lg $form-multi-select-tag-padding-x-lg;
    margin: $form-multi-select-tag-margin-y-lg $form-multi-select-tag-margin-x-lg;
    @include border-radius($form-multi-select-tag-border-radius);
  }
}