.print-font {
  font-family: $font-family-lato;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.font-7 {
  font-size: 7px;
  color: #000;
}
.font-8 {
  font-size: 8px;
  color: #000;
}
.font-9 {
  font-size: 9px;
  color: #000;
}
.font-10 {
  font-size: 10px;
  color: #000;
}
.font-12 {
  font-size: 12px;
  color: #000;
}
.font-14 {
  font-size: 14px;
  color: #000;
}
.font-16 {
  font-size: 16px;
  color: #000;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}
.font-30 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 500;
}
.font-34 {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 500;
}
.font-40 {
  font-size: 40px;
}
.line-height-9 {
  line-height: 0.9;
}
.line-height-1 {
  line-height: 1;
}
.line-height-100 {
  line-height: 100%;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
}
.text-upper-dark {
  text-transform: uppercase;
  color: #000015;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.padding-bottom-2 {
  padding-bottom: 2px;
}
.cutoff-text-1-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 1;
}
.cutoff-text-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
}
