// Custom scroll bar for Safari and Chrome
.form-select::-webkit-scrollbar {
  width: 10px;
  opacity: 0;
}

.form-select::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--cui-gray-500);
}

.form-select::-webkit-scrollbar-track {
  background: var(--cui-body-bg);
}

.form-select:hover::-webkit-scrollbar {
  display: block;
  background: var(--cui-bg-color);
}

.form-select::-webkit-scrollbar-thumb:hover {
  background: var(--cui-gray-700);
}
