svg.custom-svg {
  width: 1.3em;
  height: 1.3em;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  stroke: currentColor;
  overflow: hidden;
  stroke-width: 0;
}

svg.custom-svg_thumbnail {
  cursor: pointer;
  height: 2rem;
  width: 3rem;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  stroke: currentColor;
  overflow: hidden;
  stroke-width: 0;
}
