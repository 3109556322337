.client-name-a {
	font-size: 10px;
	font-style: normal;
	font-variant: normal;
	font-weight: 800;
	letter-spacing: normal;
	line-height: 15px;
	text-align: start;
	text-indent: 0px;
	text-transform: uppercase;
	vertical-align: baseline;
	white-space: normal;
	word-spacing: 0px;
  color: $black;


}
.client-name-aubainerie {
	font-size: 11px;
	font-style: normal;
	font-variant: normal;
	font-weight: 800;
	letter-spacing: 4px;
	line-height: 11px;
	text-decoration: none solid rgb(26, 32, 44);
	text-align: center;
	text-indent: 0px;
	text-transform: uppercase;
	vertical-align: baseline;
	white-space: normal;
	word-spacing: 0px;
  padding-left: 4px;
  color: $black;
}
.barcode-card-a {
  width: 408.6px;
  height: 121px;
}
.barcode-cover-a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11.3px;
}
.barcode-wrapper-a {
  height: 121px;
  width: 121px;
}
.client-name-b {
  font-family: lato, sans-serif;
	font-size: 11px;
	font-style: normal;
	font-variant: normal;
	font-weight: 800;
	letter-spacing: 1px;
	line-height: 16.5px;
	text-align: start;
	text-indent: 0px;
	text-transform: uppercase;
	vertical-align: baseline;
	white-space: normal;
	word-spacing: 0px;
  color: $black;
}
.barcode-card-b {
  width: 240px;
  height: 94.5px;
}
.client-name-c {
	font-size: 17px;
	font-style: normal;
	font-variant: normal;
	font-weight: 800;
	letter-spacing: 1px;
	line-height: 25.5px;
	text-align: start;
	text-indent: 0px;
	text-transform: uppercase;
	vertical-align: baseline;
	white-space: normal;
	word-spacing: 0px;
  color: $black;
}
.barcode-card-c {
  width: 386px;
  height: 193px;
}
.barcode-4x2 {
  width: 386px;
  height: 193px;
}
.barcode-3x1_5,
.barcode-default {
  width: 288px;
  height: 144px;
}
.barcode-2_5x1 {
  width: 240px;
  height: 96px;
}
.custom-mb-3rem {
  margin-bottom: 3rem;
}
@media print {
  .barcode-8_5x11 {
    width: 900px;
    height: 750px;

    .barcode-container {
      width: 900px;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;

      .location-name {
        letter-spacing: 3px;
      }
    }
  }

  .barcode-3x1_5,
  .barcode-default {
    width: 288px;
    height: 144px;

    .custom-mt-3rem {
      margin-top: 0px;
    }
  }

  .barcode-2_5x1 {
    .custom-mt-3rem {
      margin-top: 0px;
    }

    .custom-pt-1rem {
      padding-top: 1rem;
    }
  }

  .barcode-4x6 {
    width: 576px;
    height: 384px;

    &.custom-mb-3rem {
      margin-bottom: 0px;
    }
  }
}
