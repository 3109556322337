.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 999;
  transition: all 0.3s ease-in-out;
  @at-root .dark-theme & {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}

.loading-clean {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  z-index: 999;
}

.loading-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 999;
  transition: all 0.3s ease-in-out;
  @at-root .dark-theme & {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}

