.pswp::-webkit-scrollbar {
  display: none; /* Hide scroll bar for Safari and Chrome */
}

.pswp {
  -ms-overflow-style: none; /* Hide scroll bar for Internet Explorer 10+ */
  scrollbar-width: none; /* Hide scroll bar for Firefox */
}

.pswp img {
  object-fit: cover;
}

.return_img_thumbnail {
  object-fit: cover;
  cursor: pointer;
  height: 6.5rem;
  width: 7rem;
}

.img_thumbnail {
  object-fit: contain;
  cursor: pointer;
  height: 3rem;
  width: 4rem;
}

.picking_img {  
  object-fit: contain;
  height: 5rem;
  width: 4.5rem;
}

.picking_done_img {
  object-fit: contain;
  height: 5rem;
  width: 5rem;
}

.packing_img {  
  object-fit: contain;
  height: 3rem;
  width: 3rem;
}

.item_img {  
  object-fit: contain;
  height: 3rem;
  width: 3rem;
}

.package_img {  
  object-fit: fill;
  height: 6rem;
  width: 6rem;
}

.package_img-1-6 {  
  object-fit: fill;
  height: 1.6rem;
  width: 1.6rem;
}

.package_img-1-4 {  
  object-fit: fill;
  height: 1.4rem;
  width: 1.4rem;
}

.item_img_7 {  
  object-fit: fill;
  height: 7rem;
  width: 6.5rem;
}

.img_6_6 {
  object-fit: contain;
  height: 6rem;
  width: 6rem;
}

.img_4_4 {
  object-fit: contain;
  height: 4rem;
  width: 4rem;
}

.img_3_3 {
  object-fit: contain;
  height: 3rem;
  width: 3rem;
}
