.tags-background {
  width: 384px;
  height: 576px;
  background: $white;
  border-radius: 15px;
}
.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}
.border-bottom {
  border-bottom: 1px solid black !important;
}
.border-rigth {
  border-right: 1px solid black;
}
.paragraph-margin {
  margin: 10px 5px;
}
.font-20 {
  font-size: 20px;
}
.font-40 {
  font-size: 40px;
}
.barcode-margin {
  margin: 15px 5px;
}
.title-1 {
  font-size: 70px;
  margin: 0px;
  margin-left: 5px;
}
.title-2 {
  font-size: 30px;
  margin: 0px;
  margin-right: 5px;
}
