.footer-list {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 54px;
  height: 54px;
  z-index: 1000;
  font-size: 12px;
  background-color: $gray-100;
  list-style: none;
  padding: 0 10px;
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.3);
}

.footer-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-align: center;
  width: 100%;
  opacity: 0.9;
  margin: 4px 0;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  transition: opacity 0.3s;

  svg {
    display: block;
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }
}

.mobile-active-light {
  color: $black;
  font-weight: 700;
}

.mobile-active-dark {
  color: $dm3red-50;
}

.bg-footer-dark {
  background-color: $dark-gray;
  color: $gray-100;
}