// Here you can add other styles
.item-summary p:not(:first-child) {
  margin-left: 2em;
}

.minH-30 {
  min-height: 30vh;
}

.minH-78 {
  min-height: 78vh;
}

.minH-75 {
  min-height: 65vh;
}
.minH-85 {
  min-height: 85vh;
}

.minH-100 {
  min-height: 100vh;
  min-height: 100svh;
}

.maxW-200 {
  max-width: 240px;
}

.maxW-14 {
  max-width: 14rem;
}

.minW-142 {
  min-width: 142px;
}

.cursor-pointer {
  cursor: pointer;
}

.border-right-1 {
  border-right: 1px solid $gray-100;
}

.border-bottom-1 {
  border-bottom: 1px solid $gray-100;
}

.border-top-1 {
  border-top: 1px solid $gray-100;
}

.border-1 {
  border: 1px solid $gray-100;
  border-radius: 4px;
}

.grid-search-box {
  border: 1px solid $gray-100 !important;
}

.grid-p {
  border-left: 1px solid $gray-100;
  border-right: 1px solid $gray-100;

  &:hover {
    font-weight: semibold;
  }
}

.grid-p-bg-grey {
  background-color: $gray-100;
}

.grid-p-bg-none {
  background-color: none;
}

.all-data-available-grid {
  min-height: 603px;
  max-height: 603px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.all-data-selected-grid {
  min-height: 728px;
  max-height: 728px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.empty-box {
  border: 1px solid $gray-100;
  min-height: 100px;
}

.client-box-padding {
  padding: 0 12px;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.font-weight-bold {
  font-weight: bold;
}

.platform-card {
  height: 120px;
  cursor: pointer;
}

.platform-card img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.days-checkbox-list {
  width: 100px;
}

.date-picker-timepickers .time-picker .form-select {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: left;
}

.platform-image {
  object-fit: contain;
  max-width: 4rem;
  max-height: 2.3rem;
  margin-right: 1.6rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-2 {
  margin-right: 0.5rem;
}

.form-multi-select .dropdown-menu.show {
  max-height: 320px;
  overflow: auto;
}

.pull_barcode {
  margin-top: 12px;
}

.go-to-dashboard-container {
  margin-top: -30px;
}

.page-not-found-logo {
  width: 150px;
}

.page-not-found-logo-container {
  position: absolute;
  top: 30px;
  left: 30px;
}

.date-picker-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 12px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.custom-margin-top {
  margin-top: 0.35rem;
}

.custom-margin-top div {
  border-color: #b1b7c1;
}

.inner-div {
  position: absolute;
  top: 50%;
  left: 50%;
}

.rotate-90 {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.margin-right-negative-8 {
  margin-right: -8px;
}

.float-right {
  float: right;
}

.custom-mt-3rem {
  margin-top: 3rem;
}

svg.custom-svg-thick polygon {
  stroke: #000000;
  stroke-width: 40px;
}

.input-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.input-icon-right-12 {
  right: 12px;
}

.input-icon-right-36 {
  right: 36px;
}

.padding-end-66 {
  padding-right: 66px !important;
}

.font-size-0-7rem {
  font-size: 0.7rem;
}

.height-08-rem {
  height: 0.8rem !important;
}
