.sidebar-react-device-detect {
  padding-left: 4rem !important;
  @media (max-width: 768px) {
    padding-left: 0 !important;
  }
}

// remove bd from sidebar svg icons
.sidebar-nav .nav-link.active .nav-icon {
  background: none;
}

.sidebar-brand {
  @at-root .dark-theme & {
    background: $dark-gray !important;
  }  
}