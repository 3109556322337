.button {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.15px;

  background-color: $primary-base;
  color: $white;
  box-shadow: 8px 8px 16px $secondary-25, -8px -8px 16px $white;

  border: none;
  outline: none;
}

.btn-link {
  color: $gray-base;
  text-decoration: none;
  &:hover {
    color: $gray-base;
    text-decoration: underline;
  }
}

.btn-primary {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-danger {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-info {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-success {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-warning {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-outline-danger {
  color: $dm3red-base;
  &:hover {
    color: $white;
  }
}

.btn-outline-reset {
  border-color: $gray-400;
  color: $dm3red-base;
  &:hover {
    border-color: $gray-400;
    color: $dm3red-50;
  }
  &:focus {
    border-color: $gray-400;
    color: $dm3red-50;
  }
}

.btn-dm3red {
  color: $white;
  background-color: $dm3red-base;
  &:hover {
    color: $white;
    background-color: $dm3red-50;
  }
  &:focus {
    color: $white;
    background-color: $dm3red-base;
  }
  &:disabled {
    color: $white;
    background-color: $dm3red-base;
  }
  &:active {
    color: $white;
    background-color: $dm3red-50;
  }
}

.btn-outline-search {
  border-color: $gray-400;
  &:hover {
    border-color: $gray-400;
  }
  &:focus {
    border-color: $gray-400;
  }
}

.btn-outline-plus {
  &:hover {
    border-color: transparent;
    background-color: transparent;
    color: $info-50;
  }
  &:focus {
    border-color: transparent;
    background-color: transparent;
    color: $info-50;
  }
}

.btn-light {
   @at-root .dark-theme & {
    color: $gray-200 !important;
    background: $dark-brown !important;
  }  
}

.btn-group-bg-light {
  background: white !important;
  color: $gray-base !important;
  @at-root .dark-theme & {
    background: var(--input-bg) !important;
    color: $gray-200 !important;
  }
}
