// If you want to override variables do it here
@import 'variables';
// Import the font
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;800;900&display=swap');

$enable-ltr: true;
/* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true;
/* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import '@coreui/coreui-pro/scss/coreui';
@import '@coreui/coreui-pro/scss/themes/dark';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';

// Plugins
@import 'calendar';

// Prism.js
@import 'examples';

// If you want to add custom CSS you can put it here.
@import 'custom';

// scss-docs-start import-stack
// Configuration
@import 'functions';
@import 'variables';
// @import "mixins";
@import 'utilities';

// Layout & components
@import 'root';
@import 'reboot';
@import 'type';
@import 'images';
@import 'containers';
@import 'grid';
@import 'tables';
@import 'receiving-tags';
@import 'barcode';
@import 'drop-zone';
@import 'forms';
@import 'buttons';
@import 'loading-button';
@import 'loader';
@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'nav';
@import 'navbar';
@import 'card';
@import 'accordion';
@import 'breadcrumb';
@import 'pagination';
@import 'badge';
@import 'icons';
// @import 'alert';
@import 'progress';
@import 'list-group';
@import 'close';
@import 'toasts';
@import 'modal';
@import 'tooltip';
@import 'popover';
@import 'carousel';
@import 'spinners';
@import 'offcanvas';
@import 'placeholders';
@import 'text';
@import 'avatar';
@import 'callout';
@import 'footer';
@import 'footerMobile';
@import 'header';
@import 'icon';
@import 'sidebar';
@import 'subheader';
@import 'select';

// Helpers
@import 'helpers';

// Utilities
@import 'utilities/api';
@import 'utilities/bg-gradients';

// scss-docs-end import-stack
