.sidebar {
  --#{$variable-prefix}sidebar-width: #{$sidebar-width};

  position: relative;
  display: flex;
  // $sidebar-width is the width of the columns
  flex: 0 0 var(--#{$variable-prefix}sidebar-width);
  flex-direction: column;
  // put the nav on the left
  order: -1;
  width: var(--#{$variable-prefix}sidebar-width);
  padding: $sidebar-padding-y $sidebar-padding-x;
  color: var(--#{$variable-prefix}sidebar-color, $sidebar-color);
  background: var(--#{$variable-prefix}sidebar-bg, $sidebar-bg);
  @include ltr-rtl("border-right", var(--#{$variable-prefix}sidebar-border-width, $sidebar-border-width) solid var(--#{$variable-prefix}sidebar-border-color, $sidebar-border-color));
  box-shadow: none;
  @include transition($sidebar-transition);

  &:not(.sidebar-end) {
    @include ltr-rtl("margin-left", 0);

    ~* {
      --#{$variable-prefix}sidebar-occupy-start: #{$sidebar-width};
    }
  }

  &.sidebar-end {
    order: 99;
    @include ltr-rtl("margin-right", 0);

    ~* {
      --#{$variable-prefix}sidebar-occupy-end: #{$sidebar-width};
    }
  }

  &[class*="bg-"] {
    border-color: rgba($black, .1);
  }

  @each $width,
  $value in $sidebar-widths {
    &.sidebar-#{$width} {
      --#{$variable-prefix}sidebar-width: #{$value};

      @include media-breakpoint-up($mobile-breakpoint) {
        &:not(.sidebar-end):not(.hide) {
          ~* {
            --#{$variable-prefix}sidebar-occupy-start: #{$value};
          }
        }

        &.sidebar-end:not(.hide) {
          ~* {
            --#{$variable-prefix}sidebar-occupy-end: #{$value};
          }
        }
      }
    }
  }

  @include media-breakpoint-up($mobile-breakpoint) {
    &.hide {
      &:not(.sidebar-end) {
        @include ltr-rtl("margin-left", calc(-1 * var(--#{$variable-prefix}sidebar-width)));

        ~* {
          --#{$variable-prefix}sidebar-occupy-start: 0;
        }
      }

      &.sidebar-end {
        @include ltr-rtl("margin-right", calc(-1 * var(--#{$variable-prefix}sidebar-width)));

        ~* {
          --#{$variable-prefix}sidebar-occupy-end: 0;
        }
      }
    }
  }

  &.sidebar-fixed {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: $zindex-fixed;
      @include elevation(2);

      &:not(.sidebar-end) {
        @include ltr-rtl("left", 0);
      }

      &.sidebar-end {
        @include ltr-rtl("right", 0);
      }
    }
  }

  &.sidebar-sticky {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: sticky;
      top: 0;
      height: 100vh;
    }
  }

  &.sidebar-overlaid {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 2;

    &:not(.hide) {
      @include elevation(3);
    }

    &:not(.sidebar-end) {
      @include ltr-rtl("left", 0);

      ~* {
        --#{$variable-prefix}sidebar-occupy-start: 0 !important; // stylelint-disable-line
      }
    }

    &.sidebar-end {
      @include ltr-rtl("right", 0);

      ~* {
        --#{$variable-prefix}sidebar-occupy-end: 0 !important; // stylelint-disable-line
      }
    }
  }

  @include media-breakpoint-down($mobile-breakpoint) {
    // Some of our components use this property to detect if the sidebar has mobile behavior.
    --#{$variable-prefix}is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 1;

    &:not(.sidebar-end) {
      @include ltr-rtl("left", 0);

      ~* {
        --#{$variable-prefix}sidebar-occupy-start: 0 !important; // stylelint-disable-line
      }

      &:not(.show) {
        @include ltr-rtl("margin-left", calc(-1 * var(--#{$variable-prefix}sidebar-width)));
      }
    }

    &.sidebar-end {
      @include ltr-rtl("right", 0);

      ~* {
        --#{$variable-prefix}sidebar-occupy-end: 0 !important; // stylelint-disable-line
      }

      &:not(.show) {
        @include ltr-rtl("margin-right", calc(-1 * var(--#{$variable-prefix}sidebar-width)));
      }
    }
  }
}

.sidebar-close {
  position: absolute;
  top: 0;
  @include ltr-rtl("right", 0);
  width: $sidebar-brand-height;
  height: $sidebar-header-height;
  color: var(--#{$variable-prefix}sidebar-color, $sidebar-color);
  background: transparent;
  border: 0;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.sidebar-brand {
  display: flex;
  flex: 0 0 $sidebar-brand-height;
  align-items: center;
  justify-content: center;
  color: $sidebar-brand-color;
  background: $sidebar-brand-bg;

  .sidebar-brand-narrow {
    display: none;
  }
}

.sidebar-header {
  flex: 0 0 $sidebar-header-height;
  padding: $sidebar-header-padding-y $sidebar-header-padding-x;
  text-align: center;
  background: var(--#{$variable-prefix}sidebar-header-bg, $sidebar-header-bg);
  @include transition($sidebar-header-height-transition);

  .nav-link {
    display: flex;
    align-items: center;
    min-height: $sidebar-header-height;
  }
}

.sidebar-footer {
  flex: 0 0 $sidebar-footer-height;
  padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;
  background: var(--#{$variable-prefix}sidebar-footer-bg, $sidebar-footer-bg);
  @include transition($sidebar-footer-height-transition);
}

.sidebar-toggler {
  display: flex;
  flex: 0 0 $sidebar-toggler-height;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  background-color: var(--#{$variable-prefix}sidebar-toggler-bg, $sidebar-toggler-bg);
  border: 0;

  @include media-breakpoint-down($mobile-breakpoint) {
    display: none;
  }

  &::before {
    display: block;
    width: $sidebar-toggler-indicator-width;
    height: $sidebar-toggler-indicator-height;
    content: "";
    background-image: var(--#{$variable-prefix}sidebar-toggler-indicator, escape-svg($sidebar-toggler-indicator-icon));
    background-repeat: no-repeat;
    background-position: center;
    background-size: $sidebar-toggler-indicator-height * .25;
    @include transition($sidebar-toggler-transition);

    @include rtl() {
      transform: rotate(-180deg);
    }
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: var(--#{$variable-prefix}sidebar-toggler-hover-bg, $sidebar-toggler-hover-bg);

    &::before {
      background-image: var(--#{$variable-prefix}sidebar-toggler-indicator-hover, escape-svg($sidebar-toggler-indicator-hover-icon));
    }
  }

  .sidebar-end & {
    justify-content: flex-start;

    &::before {
      transform: rotate(-180deg);
    }
  }
}

// Backdrop background
.sidebar-backdrop {
  @include media-breakpoint-down($mobile-breakpoint) {
    position: fixed;
    top: 0;
    @include ltr-rtl("left", 0);
    z-index: $zindex-fixed;
    width: 100vw;
    height: 100vh;
    background-color: $sidebar-backdrop-bg;
    @include transition($sidebar-backdrop-transition);

    // Fade for backdrop
    &.fade {
      opacity: 0;
    }

    &.show {
      opacity: $sidebar-backdrop-opacity;
    }
  }
}

//
// Sidebar themes
//

.sidebar-light {
  --#{$variable-prefix}sidebar-color: #{$sidebar-light-color};
  --#{$variable-prefix}sidebar-bg: #{$sidebar-light-bg};
  --#{$variable-prefix}sidebar-border-width: #{$sidebar-light-border-width};
  --#{$variable-prefix}sidebar-border-color: #{$sidebar-light-border-color};
  --#{$variable-prefix}sidebar-brand-color: #{$sidebar-light-brand-color};
  --#{$variable-prefix}sidebar-brand-bg: #{$sidebar-light-brand-bg};
  --#{$variable-prefix}sidebar-header-bg: #{$sidebar-light-header-bg};
  --#{$variable-prefix}sidebar-nav-title-color: #{$sidebar-light-nav-title-color};
  --#{$variable-prefix}sidebar-nav-link-color: #{$sidebar-light-nav-link-color};
  --#{$variable-prefix}sidebar-nav-link-bg: #{$sidebar-light-nav-link-bg};
  --#{$variable-prefix}sidebar-nav-link-icon-color: #{$sidebar-light-nav-link-icon-color};
  --#{$variable-prefix}sidebar-nav-link-hover-color: #{$sidebar-light-nav-link-hover-color};
  --#{$variable-prefix}sidebar-nav-link-hover-bg: #{$sidebar-light-nav-link-hover-bg};
  --#{$variable-prefix}sidebar-nav-link-hover-icon-color: #{$sidebar-light-nav-link-hover-icon-color};
  --#{$variable-prefix}sidebar-nav-link-active-color: #{$sidebar-light-nav-link-active-color};
  --#{$variable-prefix}sidebar-nav-link-active-bg: #{$sidebar-light-nav-link-active-bg};
  --#{$variable-prefix}sidebar-nav-link-active-icon-color: #{$sidebar-light-nav-link-active-icon-color};
  --#{$variable-prefix}sidebar-nav-link-disabled-color: #{$sidebar-light-nav-link-disabled-color};
  --#{$variable-prefix}sidebar-nav-link-disabled-icon-color: #{$sidebar-light-nav-link-disabled-icon-color};
  --#{$variable-prefix}sidebar-nav-group-bg: #{$sidebar-light-nav-group-bg};
  --#{$variable-prefix}sidebar-nav-group-toggle-show-color: #{$sidebar-light-nav-group-toggle-show-color};
  --#{$variable-prefix}sidebar-nav-group-indicator: #{$sidebar-light-nav-group-indicator-icon};
  --#{$variable-prefix}sidebar-nav-group-indicator-hover: #{$sidebar-light-nav-group-indicator-hover-icon};
  --#{$variable-prefix}sidebar-footer-bg: #{$sidebar-light-footer-bg};
  --#{$variable-prefix}sidebar-toggler-bg: #{$sidebar-light-toggler-bg};
  --#{$variable-prefix}sidebar-toggler-hover-bg: #{$sidebar-light-toggler-hover-bg};
  --#{$variable-prefix}sidebar-toggler-indicator: #{$sidebar-light-toggler-indicator-icon};
  --#{$variable-prefix}sidebar-toggler-indicator-hover: #{$sidebar-light-toggler-indicator-hover-icon};
}